<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :bread-crumbs="false"
      no-filters
      show-date
      @filterOption="onFiltersChange"
    >
      <template v-slot:reason="{ item }">
        <td>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <div
                  v-if="item.reason"
                  v-bind="attrs"
                  class="col-reason"
                  v-on="on"
                >
                  {{ item.reason }}
                </div>
              </span>
            </template>
            <span>{{ item.reason }}</span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:http_url="{ item }">
        <div v-if="item.http_url">
          <typography
            :text="item?.http_url"
            :text-show="false"
            copy-content
          />
        </div>
      </template>

      <template v-slot:http_request="{ item }">
        <div
          v-if="item.http_request"
          class="open-link"
          @click="showMsgDetail(item.http_request)"
        >
          <v-icon>mdi-code-json</v-icon>
        </div>
      </template>

      <template v-slot:http_response="{ item }">
        <div
          v-if="item.http_response"
          class="open-link"
          @click="showMsgDetail(JSON.parse(item.http_response))"
        >
          <v-icon>mdi-code-json</v-icon>
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card
          cols="3"
          sm="3"
        >
          <v-select
            v-model="customFilter.action"
            filled
            dense
            :loading="isLoadingCommandOption"
            :items="commandOptions"
            :label="$t('select_command')"
            item-value="key"
            item-text="value"
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <message-detail-modal
            v-if="isModalOpen"
            :is-visible-dialog="isModalOpen"
            :data="selectedJSON"
            @close="isModalOpen = false"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    Typography: () => import("@/components/base/Typography.vue"),
    MessageDetailModal: () => import("../utils/MessageDetailModal.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filters: {},
      subFilters: {},
      customFilter: {},
      //
      lastVehicleID: null,
      //
      isModalOpen: false,
      selectedJSON: {},

      headers: [
        // {
        //   text: this.$t("imei"),
        //   align: "center",
        //   sortable: false,
        //   value: "imei",

        // },
        // {
        //   text: this.$t("domain"),
        //   align: "center",
        //   sortable: false,
        //   value: "domain",
        // },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "action",
        },
        {
          text: this.$t("status"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("queued_at"),
          align: "center",
          sortable: false,
          value: "queued_at",
        },
        {
          text: this.$t("executed_at"),
          align: "center",
          sortable: false,
          value: "executed_at",
        },
        {
          text: this.$t("completed_at"),
          align: "center",
          sortable: false,
          value: "completed_at",
        },
        {
          text: this.$t("description"),
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("reason"),
          align: "center",
          sortable: false,
          value: "reason",
        },
        {
          text: this.$t("http_url"),
          align: "center",
          sortable: false,
          value: "http_url",
        },

        {
          text: this.$t("http_request"),
          align: "center",
          sortable: false,
          value: "http_request",
        },
        {
          text: this.$t("http_response"),
          align: "center",
          sortable: false,
          value: "http_response",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "telematicDetails/getIsLoadingCommandListing",
      meta: "telematicDetails/getCommandsMeta",
      list: "telematicDetails/getCommandsList",
      //
      isLoadingCommandOption: "flespiCommands/getIsLoading",
      commandOptions: "flespiCommands/getFlespiCommands",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: async function () {
        if (this.name === this.selectedTab) {
          this.fetchData();
        }
      },
    },
    selectedTab: {
      handler: async function (v) {
        if (v === this.name) {
          const vd = this.optionFilters.vehicle_id;
          if (vd != this.lastVehicleID) this.fetchData();
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  // async mounted() {
  //   if (this.commandOptions.length == 0) {
  //     await this.$store.dispatch("flespiCommands/flespiCommandsOptions");
  //   }
  // },
  methods: {
    async fetchData() {
      const params = {
        ...this.options,
        ...this.customFilter,
        ...this.optionFilters,
      };
      this.lastVehicleID = this.optionFilters.vehicle_id;

      await this.$store
        .dispatch("telematicDetails/commandslist", params)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },
    showMsgDetail(row) {
      this.selectedJSON = row;
      this.isModalOpen = true;
      // this.isSelectedRow = row;
    },
  },
};
</script>
<style lang="sass" scoped>
.col-reason
  cursor: pointer
  white-space: nowrap
  width: 200px
  overflow: hidden
  text-overflow: ellipsis
</style>
